import React, { createContext, useContext, useState } from "react"

const defaultState = {
  currentLinkPage: false,
  setCurrentLinkPage: () => null,
}

const AppContext = createContext(defaultState)

const AppProvider = ({ children }) => {
  const [currentLinkPage, setCurrentLinkPage] = useState(false)

  return (
    <AppContext.Provider
      value={{
        currentLinkPage,
        setCurrentLinkPage,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useAppState = () => useContext(AppContext)

export { AppProvider, useAppState }
